// @ts-nocheck
function encodePairs(params: object, prefix: string | null = null) : string[] {
  return Object.entries(params).reduce((acc, [key, val]) => {
    let param = prefix ? `${prefix}[${key}]` : key

    if (typeof val == 'string') {
      return [`${param}=${encodeURIComponent(val)}`, ...acc]
    } else {
      return [...encodePairs(val, param), ...acc]
    }
  }, [])
}

export function encodeQuery(params: object) : string {
  return encodePairs(params).join('&')
}

export default {
  encodeQuery
}
