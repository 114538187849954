import templates from './v1/templates'
import URI from './v1/uri'
import UUID from './v1/uuid'

declare global {
  const __REI_ENV__: any;

  interface Window {
    Rei: typeof Rei.init;
  }
}

namespace Rei {
  export function init(_pk: string) {
    return {
      sellerLeadForm: (opts: FormOpts) => {
        return new SellerLeadForm(opts)
      }
    }
  }

  interface FormOpts {
    form_id: string;
    endpoint?: string;
    referer?: string;
    appearance?: object;
  }

  class SellerLeadForm {
    frameEl: HTMLIFrameElement
    uuid: string

    constructor(opts: FormOpts) {
      this.uuid = UUID.generate()

      let endpoint = opts.endpoint || __REI_ENV__.endpoint

      let query = URI.encodeQuery({
        form_id: opts.form_id,
        uuid: this.uuid,
        referer: opts.referer || document.URL,
        appearance: opts.appearance || {}
      })

      this.frameEl = templates.frame({
        frame_src: `${endpoint}/embed/v1/seller_leads?${query}`
      })

      window.addEventListener('message', (evt) => this.handleMessage(evt))
    }

    mount(el: HTMLDivElement) {
      el.appendChild(this.frameEl)
    }

    handleMessage(evt: MessageEvent)  {
      if (typeof evt.data != 'object' || evt.data.source != 'rei' || evt.data.uuid != this.uuid) {
        return
      }

      switch(evt.data.command) {
        case 'resize':
          this.frameEl.style.height = `${evt.data.payload.height}px`;

          break

        case 'open_modal':
          let modalEl = templates.modal({frame_src: evt.data.payload.frame_src})

          document.body.appendChild(modalEl)

          modalEl.addEventListener('click', (evt: MouseEvent) => {
            if ((<HTMLElement>evt.target).dataset.close == 'modal') {
              modalEl.remove()
              window.location.reload()
            }
          })

          break
      }
    }
  }
}

window.Rei = Rei.init;

export default Rei;
