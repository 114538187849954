function buildNode<T extends HTMLElement>(html: string): T {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html.trim(), "text/html")
  return <T>doc.body.firstElementChild
}

export function frame(assigns: any) {
  return buildNode<HTMLIFrameElement>(`
  <iframe src="${assigns.frame_src}"
          style="width: 100%; height: 100%;"
          frameborder="0"
          allowtransparency="true"
          scrolling="no">
  </iframe>
  `)
}

export function modal(assigns: any) {
    return buildNode<HTMLDivElement>(`
    <div class="rei-modal">
      <div class="rei-modal-container"
           style="position: fixed;
                  top: 0;
                  left: 0;
                  z-index: 1055;
                  width: 100%;
                  height: 100%;
                  outline: 0;
                  background-color: rgba(0, 0, 0, 0.8);">
        <div class="rei-modal-content"
             style="position: relative;
                    display: flex;
                    box-sizing: border-box;
                    width: 90%;
                    max-width: 760px;
                    height: calc(100% - 3.5rem);
                    margin: 1.75rem auto;
                    background: #fff;
                    overflow-y: auto;
                    overscroll-behavior-y: contain;">
          <button class="rei-close-button"
                  data-close="modal"
                  style="box-sizing: border-box;
                         position: absolute;
                         right: 8px;
                         top: 8px;
                         margin: 0;
                         padding: 8px 12px;
                         font-family: inherit;
                         text-transform: none;
                         display: inline-block;
                         font-weight: 400;
                         color: #333;
                         text-align: center;
                         text-decoration: none;
                         vertical-align: middle;
                         user-select: none;
                         background-color: transparent;
                         border: none;
                         font-size: 24px;
                         line-height: 24px;
                         cursor: pointer;
                         -webkit-appearance: button;">
            &times;
          </button>
          <iframe src="${assigns.frame_src}"
                  allowtransparency="true"
                  frameborder="0"
                  style="width: 100%;">
          </iframe>
        </div>
      </div>
    </div>
  `)
}

export default {
  frame,
  modal
}
